import React from 'react';

const Input = ({ type, value, onChange, min, max, ...props }) => {
    const handleChange = (e) => {
        const newValue = e.target.value;
        if (newValue === '' || (type === 'number' && parseFloat(newValue) === 0)) {
            onChange(e);
        } else if (type === 'number') {
            const numValue = parseFloat(newValue);
            if (!isNaN(numValue) && (!min || numValue >= min) && (!max || numValue <= max)) {
                onChange(e);
            }
        } else {
            onChange(e);
        }
    };

    return (
        <input
            type={type}
            value={value}
            onChange={handleChange}
            min={min}
            max={max}
            className="mt-1 block w-full rounded-md bg-card-bg border-gray-600 text-white focus:border-primary focus:ring-primary px-4 py-2"
            {...props}
        />
    );
};

export default Input;