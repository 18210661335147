import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getStartupSelections } from '../services/api';
import Input from '../components/ui/Input';
import Button from '../components/ui/Button';
import { Gamepad2, Share2 } from 'lucide-react';
import { formatAmount } from '../components/utils/formatAmount';

const InvestmentFlow = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [selection, setSelection] = useState(null);
    const [investmentAmount, setInvestmentAmount] = useState(500);
    const [membership, setMembership] = useState('Bronze');
    const [membershipFee, setMembershipFee] = useState(20);

    useEffect(() => {
        const fetchSelection = async () => {
            try {
                const selections = await getStartupSelections();
                const selectedSelection = selections.find(s => s.id.toString() === id);
                if (selectedSelection) {
                    setSelection(selectedSelection);
                } else {
                    console.error('Selection not found');
                }
            } catch (error) {
                console.error('Error fetching selection:', error);
            }
        };
        fetchSelection();
    }, [id]);

    useEffect(() => {
        const amount = parseFloat(investmentAmount) || 0;
        if (amount >= 500 && amount <= 2000) {
            setMembership('Bronze');
            setMembershipFee(20);
        } else if (amount > 2000 && amount <= 5000) {
            setMembership('Silver');
            setMembershipFee(50);
        } else if (amount > 5000 && amount <= 10000) {
            setMembership('Gold');
            setMembershipFee(100);
        } else {
            setMembership('');
            setMembershipFee(0);
        }
    }, [investmentAmount]);

    const handleInvestmentChange = (e) => {
        const value = e.target.value;
        if (value === '') {
            setInvestmentAmount('');
        } else {
            const numValue = parseFloat(value);
            if (!isNaN(numValue)) {
                setInvestmentAmount(Math.min(10000, Math.max(500, numValue)));
            }
        }
    };

    const handleInvest = () => {
        navigate('/register', {
            state: {
                selectionId: id,
                selectionName: selection.name,
                investmentAmount: investmentAmount,
                membership: membership,
                membershipFee: membershipFee
            }
        });
    };

    if (!selection) return <div className="text-white">Loading...</div>;

    const progress = (selection.currentFunding / selection.fundingGoal) * 100;

    return (
        <div className="max-w-2xl mx-auto mt-8 text-white">
            <div className="flex items-center space-x-2 mb-4">
                <Gamepad2 className="w-6 h-6 text-primary" />
                <h1 className="text-3xl font-bold">{selection.name}</h1>
            </div>

            {selection.expert && (
                <div className="bg-gray-800 rounded-lg mb-6 p-3">
                    <p className="text-sm italic">"{selection.description || 'No description available'}"</p>
                    <div className="flex justify-end items-center mt-2">
                        <span className="mr-2">—</span>
                        <span className="font-semibold">{selection.expert.name}</span>
                    </div>
                </div>
            )}

            <div className="bg-card-bg p-4 rounded-lg mb-6">
                <h2 className="text-xl font-semibold mb-2">Funding Progress</h2>
                <div className="flex justify-between text-sm mb-1">
                    <span>{formatAmount(selection.currentFunding)} raised</span>
                    <span>{formatAmount(selection.fundingGoal)} goal</span>
                </div>
                <div className="relative pt-1">
                    <div className="overflow-hidden h-2 text-xs flex rounded bg-gray-700">
                        <div
                            className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-primary"
                            style={{ width: `${progress}%` }}
                        ></div>
                    </div>
                    <span className="inline-block px-2 py-1 text-xs font-semibold text-gray-900 rounded-full absolute -top-1 right-0 bg-secondary">
                        {progress.toFixed(0)}%
                    </span>
                </div>
            </div>

            <div className="mb-6">
                <h2 className="text-xl font-semibold mb-2">Startups in this Selection</h2>
                {selection.startups.map((startup, index) => (
                    <div key={index} className="relative w-full h-24 overflow-hidden rounded-lg mb-4">
                        <img src={startup.imageUrl} alt={startup.name} className="w-full h-full object-cover object-center" />
                        <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-70"></div>
                        <div className="absolute bottom-0 left-0 right-0 p-3">
                            <h3 className="text-lg font-semibold text-white">{startup.name}</h3>
                            <p className="text-sm text-gray-200">{startup.description}</p>
                        </div>
                    </div>
                ))}
            </div>

            <div className="mb-6">
                <label htmlFor="investmentAmount" className="block text-sm font-medium mb-2">
                    Investment Amount ($)
                </label>
                <Input
                    type="number"
                    id="investmentAmount"
                    value={investmentAmount}
                    onChange={handleInvestmentChange}
                    min={500}
                    max={10000}
                    step="any"
                />
            </div>

            <div className="mb-6">
                <p className="text-sm font-medium">Membership Tier: <span className="font-semibold">{membership}</span></p>
                <p className="text-sm font-medium">Membership Fee: <span className="font-semibold">{formatAmount(membershipFee)}</span></p>
            </div>

            <div className="flex space-x-2">
                <Button onClick={handleInvest} className="flex-grow">
                    Invest Now
                </Button>
                <Button variant="outline" className="px-3" style={{ backgroundColor: 'var(--additional-funding-bg-color)', borderColor: 'rgb(75, 85, 99)' }}>
                    <Share2 className="h-4 w-4" />
                </Button>
            </div>
        </div>
    );
};

export default InvestmentFlow;