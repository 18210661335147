export const formatAmount = (amount, options = {}) => {
    const {
        currency = 'USD',
        locale = 'en-US',
        minimumFractionDigits = 0,
        maximumFractionDigits = 0,
        notation = 'standard'
    } = options;

    return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
        minimumFractionDigits,
        maximumFractionDigits,
        notation
    }).format(amount);
};

export const formatCompactAmount = (amount, options = {}) => {
    return formatAmount(amount, { ...options, notation: 'compact' });
};

export const formatPercentage = (value, options = {}) => {
    const {
        locale = 'en-US',
        minimumFractionDigits = 0,
        maximumFractionDigits = 2
    } = options;

    return new Intl.NumberFormat(locale, {
        style: 'percent',
        minimumFractionDigits,
        maximumFractionDigits
    }).format(value / 100);
};