import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from './ui/Button';
import { formatAmount } from './utils/formatAmount';


const SuccessMessage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { investmentAmount, selectionName } = location.state || {};

    return (
        <div className="max-w-md mx-auto mt-8 text-center text-white">
            <h2 className="text-2xl font-bold mb-4">Investment Successful!</h2>
            <p className="mb-4">Your investment of {formatAmount(investmentAmount)} has been processed successfully.</p>
            <p className="mb-4">Thank you for investing in the "{selectionName}" startup selection.</p>
            <Button onClick={() => navigate('/dashboard')} className="w-full">
                Go to Dashboard
            </Button>
        </div>
    );
};


export default SuccessMessage;