import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useUser } from '../context/UserContext';
import SteerUpLogo from './SteerUpLogo';

const Navigation = () => {
    const { user, logout } = useUser();
    const navigate = useNavigate();

    const handleLogout = () => {
        logout();
        navigate('/');
    };

    return (
        <nav className="bg-card-bg p-4">
            <div className="container mx-auto flex justify-between items-center">
                <Link to="/" className="text-primary">
                    <div className="w-32">
                        <SteerUpLogo color="currentColor" />
                    </div>
                </Link>
                <div>
                    <Link to="/" className="text-white mr-4">Explore</Link>
                    {user ? (
                        <>
                            <Link to="/dashboard" className="text-white mr-4">Dashboard</Link>
                            <button
                                onClick={handleLogout}
                                className="text-white bg-primary px-4 py-2 rounded hover:bg-opacity-80"
                            >
                                Logout
                            </button>
                        </>
                    ) : (
                        <Link to="/login" className="text-white bg-primary px-4 py-2 rounded hover:bg-opacity-80">
                            Login
                        </Link>
                    )}
                </div>
            </div>
        </nav>
    );
};

export default Navigation;