import React from 'react';

const Button = ({ children, className = '', ...props }) => {
    return (
        <button
            className={`bg-primary text-white px-4 py-2 rounded hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 focus:ring-offset-card-bg ${className}`}
            {...props}
        >
            {children}
        </button>
    );
};

export default Button;