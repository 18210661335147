import React from 'react';
import { Link } from 'react-router-dom';
import { Gamepad2, Share2, Unlock, Award } from 'lucide-react';
import { formatAmount, formatPercentage } from './utils/formatAmount';
import Button from './ui/Button';

const StartupSelectionCard = ({
    id,
    name,
    description,
    expert,
    startups,
    fundingGoal,
    currentFunding,
    backers,
    remainingDays,
    additionalFunding,
}) => {
    const fundingPercentage = fundingGoal ? Math.round((currentFunding / fundingGoal) * 100) : 0;

    return (
        <div className="bg-card-bg text-white rounded-lg shadow-md overflow-hidden">
            <div className="p-4">
                <div className="flex items-center space-x-2 mb-4">
                    <Gamepad2 className="w-6 h-6 text-primary" />
                    <h2 className="text-xl font-bold">{name || 'Unnamed Selection'}</h2>
                </div>
                {expert && (
                    <div className="bg-gray-800 rounded-lg mb-3 p-3">
                        <p className="text-sm italic">"{description || 'No description available'}"</p>
                        <div className="flex justify-end items-center mt-2">
                            <span className="mr-2">—</span>
                            <span className="font-semibold">{expert.name}</span>
                        </div>
                    </div>
                )}

                <div className="relative">
                    <div
                        className="absolute left-1/2 top-0 bottom-0 w-px -translate-x-1/2"
                        style={{ backgroundColor: 'var(--primary-color)', zIndex: 0 }}
                    ></div>

                    {startups && startups.length > 0 ? (
                        startups.map((startup, index) => (
                            <div key={index} className="relative mb-4 last:mb-0">
                                <div className="relative w-full h-24 overflow-hidden rounded-lg mb-2">
                                    <img src={startup.imageUrl} alt={startup.name} className="w-full h-full object-cover object-center" />
                                    <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-70"></div>
                                    <div className="absolute bottom-0 left-0 right-0 p-3">
                                        <h3 className="text-base font-semibold mb-0.5 text-white">{startup.name}</h3>
                                        <p className="text-xs text-gray-200 line-clamp-2">{startup.description}</p>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className="text-sm my-4">No startups in this selection</p>
                    )}
                </div>

                <div className="mt-6 space-y-6">
                    <div>
                        <h3 className="text-lg font-semibold mb-2">
                            Goal: {formatAmount(currentFunding || 0)} / {formatAmount(fundingGoal || 0)}
                        </h3>
                        <div className="flex items-center text-sm mb-4">
                            <span>{remainingDays || 0} REMAINING DAYS</span>
                            <span className="mx-2">●</span>
                            <span>{backers || 0} BACKERS</span>
                        </div>
                        <div className="relative pt-1">
                            <div className="overflow-hidden h-2 text-xs flex rounded bg-gray-700">
                                <div
                                    style={{ width: `${fundingPercentage}%` }}
                                    className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-primary"
                                ></div>
                            </div>
                            <span className="inline-block px-2 py-1 text-xs font-semibold text-gray-900 rounded-full absolute -top-1 right-0 bg-secondary">
                                {formatPercentage(fundingPercentage)}
                            </span>
                        </div>
                    </div>

                    <div className="flex space-x-2">
                        <Link to={`/invest/${id}`} className="flex-grow">
                            <Button className="w-full">Contribute</Button>
                        </Link>
                        <Button variant="outline" className="px-3" style={{ backgroundColor: 'var(--additional-funding-bg-color)', borderColor: 'rgb(75, 85, 99)' }}>
                            <Share2 className="h-4 w-4" />
                        </Button>
                    </div>

                    {additionalFunding && additionalFunding.length > 0 && (
                        <div>
                            <h3 className="text-sm font-semibold mb-3">ADDITIONAL FUNDING</h3>
                            <div className="space-y-3">
                                {additionalFunding.map((funding, index) => (
                                    <div
                                        key={index}
                                        className="relative overflow-hidden rounded-lg border border-gray-600 transition duration-300 ease-in-out hover:border-gray-500"
                                        style={{
                                            backgroundColor: 'var(--additional-funding-bg-color)',
                                            boxShadow: 'inset 0 1px 0 0 rgba(255, 255, 255, 0.05)'
                                        }}
                                    >
                                        <div className="absolute inset-0 bg-gradient-to-br from-transparent to-black opacity-50"></div>
                                        <div className="relative flex items-center justify-between p-2 pt-1 z-10">
                                            <div className="flex flex-col">
                                                <span className="text-xs font-semibold mb-1">{funding.type || 'Unknown Type'}</span>
                                                <div className="flex items-center space-x-2">
                                                    <Unlock className="h-4 w-4" />
                                                    <span className="px-3 py-1 text-md font-bold rounded-full text-gray-900 bg-secondary" style={{ width: "125px" }}>
                                                        + {formatAmount(funding.amount || 0)}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="text-right">
                                                <div className="flex items-center justify-end">
                                                    {funding.source?.name === expert?.name && (
                                                        <Award className="h-4 w-4 mr-1" style={{ color: 'var(--expert-badge-color)' }} />
                                                    )}
                                                    <span className="text-sm">{funding.source?.name || 'Unknown Source'}</span>
                                                </div>
                                                <p className="text-xs">{funding.source?.type || 'Unknown Type'}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                </div>
            </div>
        </div>
    );
};

export default StartupSelectionCard;