import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../components/ui/Button';

const NotFound = () => {
    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-card-bg">
            <h1 className="text-4xl font-bold mb-4 text-white">404 - Page Not Found</h1>
            <p className="text-xl mb-8 text-gray-300">Oops! The page you're looking for doesn't exist.</p>
            <Link to="/">
                <Button>Go Home</Button>
            </Link>
        </div>
    );
};

export default NotFound;