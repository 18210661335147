import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Input from './ui/Input';
import Button from './ui/Button';

const questions = [
    "What is your annual income?",
    "What is your net worth?",
    "What is your investment experience?",
    "What is your risk tolerance?",
    "Are you an accredited investor?"
];

const KYCQuestions = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { name, email, startupId, investmentAmount, membership } = location.state || {};

    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [answers, setAnswers] = useState({});
    const [currentAnswer, setCurrentAnswer] = useState('');

    const handleAnswer = () => {
        if (currentAnswer.trim()) {
            setAnswers({ ...answers, [currentQuestion]: currentAnswer });
            setCurrentAnswer('');

            if (currentQuestion < questions.length - 1) {
                setCurrentQuestion(currentQuestion + 1);
            } else {
                // KYC completed, move to bank details
                navigate('/bank-details', {
                    state: { name, email, startupId, investmentAmount, membership, kycAnswers: { ...answers, [currentQuestion]: currentAnswer } }
                });
            }
        }
    };

    return (
        <div className="max-w-md mx-auto mt-8 text-white">
            <h2 className="text-2xl font-bold mb-4">KYC Questionnaire</h2>
            <p className="mb-2">Question {currentQuestion + 1} of {questions.length}</p>
            <p className="text-lg font-medium mb-4">{questions[currentQuestion]}</p>
            <Input
                type="text"
                value={currentAnswer}
                onChange={(e) => setCurrentAnswer(e.target.value)}
            />
            <Button onClick={handleAnswer} className="w-full mt-4">
                {currentQuestion < questions.length - 1 ? 'Next Question' : 'Complete KYC'}
            </Button>
        </div>
    );
};

export default KYCQuestions;