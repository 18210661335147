import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Input from './ui/Input';
import Button from './ui/Button';

const Registration = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { startupId, investmentAmount, membership, membershipFee } = location.state || {};

    const [name, setName] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        if (name.trim()) {
            console.log('Registration submitted:', { name, startupId, investmentAmount, membership, membershipFee });
            navigate('/login', { state: { name, startupId, investmentAmount, membership, membershipFee } });
        }
    };

    return (
        <div className="max-w-md mx-auto mt-8">
            <h2 className="text-2xl font-bold mb-4 text-white">Register to Invest</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label htmlFor="name" className="block text-sm font-medium text-white mb-1">
                        Full Name
                    </label>
                    <Input
                        type="text"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </div>
                <Button type="submit" className="w-full">
                    Continue
                </Button>
            </form>
        </div>
    );
};

export default Registration;