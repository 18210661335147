import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUser } from '../context/UserContext';
import Input from './ui/Input';
import Button from './ui/Button';

const Login = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { login } = useUser();
    const { name, startupId, investmentAmount, membership, membershipFee } = location.state || {};

    const [email, setEmail] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        if (email.trim()) {
            login({ name, email });
            navigate('/kyc', { state: { name, email, startupId, investmentAmount, membership, membershipFee } });
        }
    };

    const handleSocialLogin = (provider) => {
        login({ name, email: `${name.toLowerCase()}@${provider.toLowerCase()}.com` });
        navigate('/kyc', { state: { name, startupId, investmentAmount, membership, membershipFee, socialLogin: provider } });
    };

    return (
        <div className="max-w-md mx-auto mt-8 text-white">
            <h2 className="text-2xl font-bold mb-4">Login or Sign Up</h2>
            <form onSubmit={handleSubmit} className="space-y-4 mb-4">
                <div>
                    <label htmlFor="email" className="block text-sm font-medium mb-1">
                        Email
                    </label>
                    <Input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <Button type="submit" className="w-full">
                    Continue with Email
                </Button>
            </form>
            <div className="space-y-2">
                <Button onClick={() => handleSocialLogin('Google')} className="w-full bg-red-600">
                    Continue with Google
                </Button>
                <Button onClick={() => handleSocialLogin('Apple')} className="w-full bg-black">
                    Continue with Apple
                </Button>
            </div>
        </div>
    );
};

export default Login;