import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { UserProvider } from './context/UserContext';
import Navigation from './components/Navigation';
import ProtectedRoute from './components/ProtectedRoute';
import ExploreOpportunities from './pages/ExploreOpportunities';
import InvestmentFlow from './pages/InvestmentFlow';
import Registration from './components/Registration';
import Login from './components/Login';
import KYCQuestions from './components/KYCQuestions';
import BankDetails from './components/BankDetails';
import InvestmentConfirmation from './components/InvestmentConfirmation';
import SuccessMessage from './components/SuccessMessage';
import UserDashboard from './pages/UserDashboard';
import NotFound from './pages/NotFound';

function App() {
    return (
        <UserProvider>
            <div className="App bg-gray-900 min-h-screen text-white">
                <Navigation />
                <main className="container mx-auto px-4 py-8">
                    <Routes>
                        <Route path="/" element={<ExploreOpportunities />} />
                        <Route path="/invest/:id" element={<InvestmentFlow />} />
                        <Route path="/register" element={<Registration />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/kyc" element={
                            <ProtectedRoute>
                                <KYCQuestions />
                            </ProtectedRoute>
                        } />
                        <Route path="/bank-details" element={
                            <ProtectedRoute>
                                <BankDetails />
                            </ProtectedRoute>
                        } />
                        <Route path="/confirm-investment" element={
                            <ProtectedRoute>
                                <InvestmentConfirmation />
                            </ProtectedRoute>
                        } />
                        <Route path="/investment-success" element={
                            <ProtectedRoute>
                                <SuccessMessage />
                            </ProtectedRoute>
                        } />
                        <Route path="/dashboard" element={
                            <ProtectedRoute>
                                <UserDashboard />
                            </ProtectedRoute>
                        } />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </main>
            </div>
        </UserProvider>
    );
}

export default App;