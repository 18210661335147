import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from './ui/Button';

const InvestmentConfirmation = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { name, email, startupId, selectionName, investmentAmount, membership, membershipFee, kycAnswers, bankDetails } = location.state || {};

    const [isProcessing, setIsProcessing] = useState(false);

    // Ensure investmentAmount and membershipFee are numbers, defaulting to 0 if undefined
    const safeInvestmentAmount = typeof investmentAmount === 'number' ? investmentAmount : 0;
    const safeMembershipFee = typeof membershipFee === 'number' ? membershipFee : 0;

    const totalAmount = safeInvestmentAmount + safeMembershipFee;

    const handleConfirm = async () => {
        setIsProcessing(true);
        // In a real app, we would send this data to an API
        console.log('Investment confirmed:', { name, email, startupId, investmentAmount, membership, membershipFee, kycAnswers, bankDetails });

        // Simulate API call
        await new Promise(resolve => setTimeout(resolve, 2000));

        setIsProcessing(false);

        // Navigate to success page
        navigate('/investment-success', {
            state: {
                investmentAmount: safeInvestmentAmount, membershipFee: safeMembershipFee, totalAmount, startupId, selectionName: selectionName
            }
        });
    };

    // Function to safely format currency
    const formatCurrency = (amount) => {
        return typeof amount === 'number'
            ? `$${amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
            : 'N/A';
    };

    return (
        <div className="max-w-md mx-auto mt-8 text-white">
            <h2 className="text-2xl font-bold mb-4">Confirm Your Investment</h2>
            <div className="bg-card-bg p-6 rounded-lg mb-6">
                <p><strong>Name:</strong> {name || 'N/A'}</p>
                <p><strong>Email:</strong> {email || 'N/A'}</p>
                <p><strong>Selection:</strong> {selectionName || 'N/A'}</p>
                <p><strong>Investment Amount:</strong> {formatCurrency(investmentAmount)}</p>
                <p><strong>Membership Tier:</strong> {membership || 'N/A'}</p>
                <p><strong>Membership Fee:</strong> {formatCurrency(membershipFee)}</p>
                <p><strong>Total Amount:</strong> {formatCurrency(investmentAmount + membershipFee)}</p>
            </div>
            <Button
                onClick={handleConfirm}
                disabled={isProcessing}
                className="w-full"
            >
                {isProcessing ? 'Processing...' : 'Confirm Investment'}
            </Button>
        </div>
    );
};

export default InvestmentConfirmation;