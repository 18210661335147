import React from 'react';

const StartupPortfolio = ({ portfolio }) => {
    return (
        <div className="bg-card-bg shadow rounded-lg p-6">
            <h2 className="text-2xl font-semibold mb-4 text-white">Your Startup Portfolio</h2>
            <div className="overflow-x-auto">
                <table className="w-full">
                    <thead>
                        <tr className="bg-gray-700">
                            <th className="p-2 text-left text-white">Startup</th>
                            <th className="p-2 text-left text-white">Initial Investment</th>
                            <th className="p-2 text-left text-white">Current Value</th>
                            <th className="p-2 text-left text-white">Performance</th>
                        </tr>
                    </thead>
                    <tbody>
                        {portfolio.map((item) => (
                            <tr key={item.id} className="border-b border-gray-700">
                                <td className="p-2 text-white">{item.startupName}</td>
                                <td className="p-2 text-white">${item.initialInvestment.toLocaleString()}</td>
                                <td className="p-2 text-white">${item.currentValue.toLocaleString()}</td>
                                <td className="p-2">
                                    <span className={item.performance >= 0 ? 'text-green-500' : 'text-red-500'}>
                                        {item.performance.toFixed(2)}%
                                    </span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default StartupPortfolio;