import React, { useState, useEffect } from 'react';
import { useUser } from '../context/UserContext';
import { getUserData } from '../services/api';
import StartupPortfolio from '../components/StartupPortfolio';

const UserDashboard = () => {
    const { user } = useUser();
    const [userData, setUserData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const data = await getUserData();
                setUserData(data);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching user data:', error);
                setIsLoading(false);
            }
        };

        fetchUserData();
    }, []);

    if (isLoading) {
        return <div className="text-center mt-8 text-white">Loading...</div>;
    }

    return (
        <div className="max-w-4xl mx-auto mt-8 text-white">
            <h1 className="text-3xl font-bold mb-8">Welcome, {user.name}!</h1>

            <div className="bg-card-bg shadow rounded-lg p-6 mb-8">
                <h2 className="text-2xl font-semibold mb-4">Account Summary</h2>
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <p className="text-gray-400">Total Invested</p>
                        <p className="text-2xl font-bold">${userData.accountSummary.totalInvested.toLocaleString()}</p>
                    </div>
                    <div>
                        <p className="text-gray-400">Current Portfolio Value</p>
                        <p className="text-2xl font-bold">${userData.accountSummary.currentPortfolioValue.toLocaleString()}</p>
                    </div>
                    <div>
                        <p className="text-gray-400">Total Return</p>
                        <p className="text-2xl font-bold">${userData.accountSummary.totalReturn.toLocaleString()}</p>
                    </div>
                    <div>
                        <p className="text-gray-400">Performance</p>
                        <p className="text-2xl font-bold">{userData.accountSummary.performancePercentage.toFixed(2)}%</p>
                    </div>
                </div>
            </div>

            <StartupPortfolio portfolio={userData.portfolio} />
        </div>
    );
};

export default UserDashboard;