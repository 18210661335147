import { mockStartupSelections, mockStartups, mockUserData } from './mockData';

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export const getStartupSelections = async () => {
    await delay(75);
    return mockStartupSelections;
};

export const getStartupById = async (id) => {
    await delay(75);
    const startup = mockStartups.find(s => s.id.toString() === id);
    if (startup) {
        return startup;
    } else {
        throw new Error('Startup not found');
    }
};

export const getStartupSelectionById = async (id) => {
    await delay(75);
    const selection = mockStartupSelections.find(s => s.id.toString() === id);
    if (selection) {
        return selection;
    } else {
        throw new Error('Startup selection not found');
    }
};

export const getUserData = async () => {
    await delay(50);
    return mockUserData;
};

export const registerUser = async (userData) => {
    await delay(100);
    console.log('User registered:', userData);
    return { success: true, message: 'User registered successfully' };
};

export const loginUser = async (credentials) => {
    await delay(100);
    console.log('User logged in:', credentials);
    return { success: true, user: mockUserData };
};

export const submitKYC = async (kycData) => {
    await delay(150);
    console.log('KYC submitted:', kycData);
    return { success: true, message: 'KYC submitted successfully' };
};

export const linkBankAccount = async (bankDetails) => {
    await delay(150);
    console.log('Bank account linked:', bankDetails);
    return { success: true, message: 'Bank account linked successfully' };
};

export const makeInvestment = async (investmentDetails) => {
    await delay(200);
    console.log('Investment made:', investmentDetails);
    return { success: true, message: 'Investment processed successfully' };
};