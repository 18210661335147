export const mockExperts = [
    { id: 1, name: "Dr. Sarah Johnson", credentials: "Ph.D. in Computer Science, VR Research Lead" },
    { id: 2, name: "Prof. Michael Green", credentials: "Professor of Environmental Studies, Game Design Consultant" },
    { id: 3, name: "Dr. Emily Chen", credentials: "Neuroscientist, Former CTO of BrainTech Innovations" }
];

export const mockFundingSources = {
    individuals: [
        { id: 1, name: "Dr. Sarah Johnson", type: "Lead Expert" },
        { id: 2, name: "Prof. Michael Green", type: "Lead Expert" },
        { id: 3, name: "Dr. Emily Chen", type: "Lead Expert" },
        { id: 4, name: "John Doe", type: "Angel Investor" }
    ],
    organizations: [
        { id: 1, name: "VR Innovation Fund", type: "Private Fund" },
        { id: 2, name: "Environmental Education Initiative", type: "Non-Profit" },
        { id: 3, name: "McGill University", type: "Public University" },
        { id: 4, name: "Ministère de l'Économie et de l'Innovation", type: "Governmental" }
    ]
};

export const mockStartups = [
    {
        id: 1,
        name: "VRcade",
        description: "Creating next-gen VR arcade experiences for multiplayer gaming.",
        imageUrl: "/images/startups/vrcade.png",
        fundingGoal: 200000,
        currentFunding: 200500,
        backers: 227,
        remainingDays: 3,
        leadExpert: mockExperts[0],
        additionalFunding: [
            { amount: 300000, source: mockFundingSources.individuals[2], type: "Investment" },
            { amount: 100000, source: mockFundingSources.organizations[2], type: "Grant" }
        ]
    },
    {
        id: 2,
        name: "EcoQuest",
        description: "Developing educational games focused on environmental awareness.",
        imageUrl: "/images/startups/ecoquest.png",
        fundingGoal: 180000,
        currentFunding: 180500,
        backers: 175,
        remainingDays: 3,
        leadExpert: mockExperts[1],
        additionalFunding: [
            { amount: 220000, source: mockFundingSources.individuals[2], type: "Investment" },
            { amount: 80000, source: mockFundingSources.organizations[2], type: "Grant" }
        ]
    },
    {
        id: 3,
        name: "NeuroPlay",
        description: "Brain-computer interface games for improved cognitive function.",
        imageUrl: "/images/startups/neuroplay.png",
        fundingGoal: 220000,
        currentFunding: 222550,
        backers: 237,
        remainingDays: 3,
        leadExpert: mockExperts[2],
        additionalFunding: [
            { amount: 300000, source: mockFundingSources.individuals[2], type: "Investment" },
            { amount: 500000, source: mockFundingSources.organizations[3], type: "Grant" }
        ]
    }
];

const sumAdditionalFunding = (startups) => {
    const fundingMap = new Map();

    startups.forEach(startup => {
        startup.additionalFunding.forEach(funding => {
            const key = `${funding.source.id}-${funding.source.type}-${funding.type}`;
            if (fundingMap.has(key)) {
                fundingMap.set(key, {
                    ...funding,
                    amount: fundingMap.get(key).amount + funding.amount
                });
            } else {
                fundingMap.set(key, { ...funding });
            }
        });
    });

    return Array.from(fundingMap.values());
};

export const mockStartupSelections = [
    {
        id: 1,
        name: "Immersive Gaming Experience",
        description: "A collection of startups revolutionizing the gaming industry with immersive experiences.",
        expert: mockExperts[0],
        startups: mockStartups,
        fundingGoal: mockStartups.reduce((sum, startup) => sum + startup.fundingGoal, 0),
        currentFunding: mockStartups.reduce((sum, startup) => sum + startup.currentFunding, 0),
        backers: mockStartups.reduce((sum, startup) => sum + startup.backers, 0),
        remainingDays: Math.min(...mockStartups.map(startup => startup.remainingDays)),
        additionalFunding: sumAdditionalFunding(mockStartups)
    }
];

export const mockUserData = {
    name: "John Smith",
    email: "john.smith@example.com",
    investments: [
        { id: '1', startupName: 'VRcade', amount: 1000, date: '2023-05-15' },
        { id: '2', startupName: 'EcoQuest', amount: 1500, date: '2023-06-01' },
    ],
    portfolio: [
        { id: '1', startupName: 'VRcade', initialInvestment: 1000, currentValue: 1200, performance: 20 },
        { id: '2', startupName: 'EcoQuest', initialInvestment: 1500, currentValue: 1600, performance: 6.67 },
    ],
    accountSummary: {
        totalInvested: 2500,
        currentPortfolioValue: 2800,
        totalReturn: 300,
        performancePercentage: 12
    }
};