import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Input from './ui/Input';
import Button from './ui/Button';

const BankDetails = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { name, email, startupId, investmentAmount, membership, kycAnswers } = location.state || {};

    const [accountNumber, setAccountNumber] = useState('');
    const [routingNumber, setRoutingNumber] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        if (accountNumber.trim() && routingNumber.trim()) {
            console.log('Bank details submitted:', { accountNumber, routingNumber });
            navigate('/confirm-investment', {
                state: { name, email, startupId, investmentAmount, membership, kycAnswers, bankDetails: { accountNumber, routingNumber } }
            });
        }
    };

    return (
        <div className="max-w-md mx-auto mt-8 text-white">
            <h2 className="text-2xl font-bold mb-4">Enter Bank Details</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label htmlFor="accountNumber" className="block text-sm font-medium mb-1">
                        Account Number
                    </label>
                    <Input
                        type="text"
                        id="accountNumber"
                        value={accountNumber}
                        onChange={(e) => setAccountNumber(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="routingNumber" className="block text-sm font-medium mb-1">
                        Routing Number
                    </label>
                    <Input
                        type="text"
                        id="routingNumber"
                        value={routingNumber}
                        onChange={(e) => setRoutingNumber(e.target.value)}
                        required
                    />
                </div>
                <Button type="submit" className="w-full">
                    Confirm Bank Details
                </Button>
            </form>
        </div>
    );
};

export default BankDetails;