import React, { useState, useEffect } from 'react';
import StartupSelectionCard from '../components/StartupSelectionCard';
import { getStartupSelections } from '../services/api';

const ExploreOpportunities = () => {
  const [selections, setSelections] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchSelections = async () => {
      try {
        const data = await getStartupSelections();
        setSelections(data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching startup selections:', error);
        setIsLoading(false);
      }
    };

    fetchSelections();
  }, []);

  if (isLoading) {
    return <div className="text-center mt-8">Loading...</div>;
  }

  return (
    <div className="py-8">
      <h1 className="text-3xl font-bold mb-8 text-center text-white">Explore Opportunities</h1>
      <div className="flex justify-center">
        <div className="w-full max-w-6xl">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {selections.map((selection) => (
              <StartupSelectionCard key={selection.id} {...selection} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExploreOpportunities;